@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__hero-image {
  .hero-image {
    text-align: center;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    min-height: 500px;
    display: flex;
    align-items: center;
  }
}
