@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.featuredcard {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  position: relative;
  flex-direction: row;
  margin-right: 0;
  box-shadow: 35px 35px 0px 0px var(--sp-color-secondary);
  margin-bottom: 50px;

  // TODO: This selector is overwritten below - consolidate it?
  .featuredcard__content {
    margin: 0;
    position: relative;
    background-color: var(--sp-color-primary);
    color: #fff;
    height: 80%;
    bottom: 0;
    align-self: flex-end;
    padding: 25px;
    border: none;
    flex: 1;
    
    a {
      color: var(--sp-color-accent-one);
    }
  }

  &--with-image {
    .featuredcard__content {
      @include media-breakpoint-up(lg) {
        right: 50px;
        max-width: 50%;
        box-shadow: 40px -40px 0 -10px var(--sp-color-secondary);
      }
    }

    @include media-breakpoint-up(lg) {
      box-shadow: none;
      margin-right: 2.5em;
      margin-bottom: 25px;
    }

    .news-featured-image {
      position: relative;
      @include media-breakpoint-up(lg) {
        max-width: 50%;
      }

      img {
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 2;
    }
  }

  &--without-image {
    background-color: var(--sp-color-secondary);
    display: flex;

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 2;
    }
  }

  // content wrapper
  &__content {
    padding: $spacer $spacer $spacer;
    margin: $spacer;
    position: relative;
    z-index: 150;
    background: $white;

    @include media-breakpoint-up(lg) {
      padding: $spacer $spacer * 2 $spacer * 2;
      margin: $spacer * 2;
    }
  }

  &__meta {
    font-family: var(--sp-font-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.71;

    .date,
    p {
      display: inline-block;
    }

    a {
      color: $white;
    }
  }

  .contentWrapper {
    font-size: 16px;
    line-height: 1.78;
    color: $dark-gray;
    font-family: var(--sp-font-primary);
    word-break: break-word;
    color: $white;
  }

  .heading--featuredcard {
    @include heading-base;
    margin: $spacer * 0.33 0;
    font-weight: 600;
    font-size: 26px;

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .featuredcard {
    margin-left: 0;
    box-shadow: none;

    .news-featured-image {
      max-width: 100%;
      top: -20px;
    }

    &__content {
      padding: $spacer $spacer $spacer;
      margin: 2 * $spacer;
      position: relative;
      z-index: 150;
      background: var(--sp-color-primary); //$blue;
      max-width: 100%;
      box-shadow: none;
      right: 0;

      @include media-breakpoint-up(lg) {
        padding: $spacer $spacer $spacer;
      }
    }

  &--with-image {
    padding-right: 2*$spacer;
    background: var(--sp-color-secondary);
    box-shadow: none;

      .featuredcard__content {
        margin-right: 0;
        margin-top: -60px;
      }
    }

    &--without-image {
      .featuredcard__content {
        top: 0;
      }
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__newslist {
    // TODO: Move teasercard to appropriate place
    .teasercard {
      .heading {
        font-size: 20px;
      }
    }
  }
}
