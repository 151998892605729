@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__storylist {
  font-family: var(--sp-font-secondary);
  padding-bottom: $spacer * 4;

  .story-card {
    &.featured {
      margin-top: $spacer * 2;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin-right: 0;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 !important;
        margin: 0 !important;
      }

      .storyContentWrapper,
      .story-featured-image {
        position: relative;
        max-width: calc(100% + 4.55em);
        padding-right: 15px;
        padding-left: 15px;

        @include media-breakpoint-up(lg) {
          max-width: 50%;
        }
      }

      .story-featured-image {
        padding: 0;

        img {
          width: 100%;
          position: relative;
        }
      }

      .storyContentWrapper {
        position: relative;
        background-color: var(--sp-color-primary);
        color: $white;
        height: 80%;
        width: 100%;
        bottom: 0;
        padding: 25px;

        @include media-breakpoint-up(lg) {
          border: none;
          box-shadow: 40px -40px 0 -10px var(--sp-color-secondary);
          right: 50px;
        }

        @include media-breakpoint-down(sm) {
          border: none;
        }

        .heading {
          margin: 0 0 15px;
          font-size: 26px;

          a {
            color: #fff;
          }
        }
      }

      @include media-breakpoint-down(md) {
        box-shadow: 35px 35px 0px 0px var(--sp-color-secondary) !important;
      }
    }

    &__meta {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 1.71;
    }
  }

  .featuredWrapper {
    margin-bottom: $spacer * 4;
  }

  .nonFeaturedWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    @include media-breakpoint-down(md) {
      .story-card {
        border-bottom: 1px solid var(--sp-color-neutral-accent-one);
        width: inherit;
        padding-bottom: 30px;

        .date {
          margin-top: 10px;
        }

        &:last-of-type {
          border-bottom: none;
        }

        img {
          display: none;
        }
      }
    }

    .nonFeaturedCard {
      width: 100%;
			padding-bottom: $spacer*1.5;

      @include media-breakpoint-up(lg) {
        max-width: 48.5%;
      }

      .story-featured-image {
        padding: 0;

        img {
          position: relative;
          display: none;

          @include media-breakpoint-up(lg) {
            display: block;
          }
        }
      }
    }

    .date {
      font-size: 0.9rem;
			text-transform: uppercase;
      font-weight: 600;
      line-height: 1.71;
      letter-spacing: 1px;
      color: $dark-gray;
      margin-top: $spacer;
    }

    .heading {
      margin-top: 0;
      text-transform: initial;
			font-family: var(--sp-font-primary);
      font-weight: normal;
			font-size: 1.1rem;
    }
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__storylist {
    .story-card {
      &.featured {
        flex-direction: column;
        justify-content: flex-end;

        .story-featured-image {
          max-width: calc(100% + 4.55em);
          padding: 0;

          img {
            max-width: 100%;
          }
        }

        .storyContentWrapper {
          max-width: 650px;
          right: 0;
          box-shadow: none;
          border: solid $spacer * 2 var(--sp-color-secondary);
          border-bottom: solid $spacer * 2 var(--sp-color-secondary);
          border-top: none;
          border-left: none;

          @include media-breakpoint-down(md) {
            border: none;
          }
        }
      }
    }
    
    .nonFeaturedCard {

      @include media-breakpoint-up(lg) {
        max-width: 100%;
      }
    }  

    .nonFeaturedWrapper {
      flex-direction: column;

      .story-card {
        border-bottom: 1px solid var(--sp-color-neutral-accent-one);
        width: inherit;
				padding-bottom: $spacer*1.5;

        .date {
          margin-top: 10px;
        }

        &:last-of-type {
          border-bottom: none;
        }

        img {
          display: none;
        }
      }
    }
  }
}
