@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__hero-video-text {
  text-align: right;

  .row {
    margin: 60px 0;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      margin-top: 0;
    }
  }

  .hero-text {
    background: var(--sp-color-primary);
    color: #fff;
    height: 100%;
    box-shadow: 0 0 0 30px var(--sp-color-primary), -140px 25px 0px 35px var(--sp-color-secondary);

    @include media-breakpoint-down(md) {
      padding-left: 0;
      box-shadow: 30px 0 0 50px var(--sp-color-primary), -125px 25px 0px 35px var(--sp-color-secondary);
      margin-top: 30px;
      text-align: left;
    }

    .eyebrow {
      color: $yellow;
      margin: 0;
    }

    h2 {
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: calc(1.375rem + 0.5vw);
      }
    }

    h3 {
      font-weight: 300;
      text-transform: initial;

      @include media-breakpoint-down(md) {
        font-size: calc(1.2875rem);
      }
    }

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      color: #fff;
    }

    a {
      font-family: var(--sp-font-secondary);
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fff;

      &.button {
        text-align: left;
        color: $white;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        font-size: 14px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
